import ChatInfo from "@/components/chatScreen/ChatInfo";
import ChatScreen, {
  ChatScreenHandle,
} from "@/components/chatScreen/ChatScreen";
import { discardMessageNotificationAndOverlay } from "@/components/navigation/Navigation";
import SlideOnTop from "@/components/shared/SlideOnTop";
import { scrollbarWidth } from "@/styles/global.styles";
import { atoms } from "@/utils/helpers/atoms";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { useFilePreview } from "@/utils/hooks/useFilePreview";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { disableIncomingNotification } from "@/utils/messaging/conversation/conversationUtils";
import { useAtomValue } from "jotai";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import BannerCarousel from "../../components/chatbotScreen/BannerCarousel";
import CategorizedBots from "../../components/chatbotScreen/CategorizedBots";
import ChatBotList from "../../components/chatbotScreen/ChatbotList";
import SearchBar from "../../components/shared/SearchBar";
import SvgGraphic from "../../components/shared/SvgGraphic";
import { Box } from "../layout/Layout.style";

export default function ChatbotScreen() {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <>
      <Box css={{ paddingBottom: "0" }}>
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          css={{ marginBottom: "1em" }}
        />
        <div
          css={{
            width: "100%",
            // ! hardcoded height
            // 100% - wrapper box top padding - (height of search bar + its margin)
            height: `calc(100% - 3% - (2.5em + 1em))`,
            paddingRight: "8px",
          }}
        >
          <div
            css={{
              width: "100%",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              paddingTop: "1em",
              paddingLeft: `calc(8px + ${scrollbarWidth})`,
              marginBottom: "3%",
              gap: "1.5em",
            }}
          >
            <Suspense fallback={null}>
              {!searchQuery && (
                <>
                  <BannerCarousel />
                  <CategorizedBots />
                </>
              )}
              <ChatBotList searchQuery={searchQuery} />
            </Suspense>
          </div>
        </div>
      </Box>
      <Box css={{ padding: "0", position: "relative" }}>
        <ChatBotInfoWithChatBox />
      </Box>
    </>
  );
}

function ChatBotInfoWithChatBox() {
  const selectedChatbot = useAtomValue(atoms.chatbot.selectedChatbot);

  return selectedChatbot ? (
    <InnerChatBotInfoWithChatBox
      key={selectedChatbot?.id}
      selectedChatbot={selectedChatbot}
    />
  ) : (
    <SvgGraphic src="/assets/Chatbot_Graphic.svg" />
  );
}

function InnerChatBotInfoWithChatBox({
  selectedChatbot,
}: {
  selectedChatbot: WebGwContact;
}) {
  const [chatOpen, setChatOpen] = useState(false);
  // This flag allows us to not include the chat component after slide off, in order to not automatically send the read report if view is just hidden
  const [show, setShow] = useState(chatOpen);
  const conversation = useMemo(() => {
    return Conversation.getOrCreate({
      phoneNumber: selectedChatbot.getMainPhoneNumber(),
      contactToLinkIfCreate: selectedChatbot,
    }).conversation;
  }, [selectedChatbot]);

  const [key, rerenderChat] = useReducer((prev: number) => prev + 1, 0);
  const chatScreenRef = useRef<ChatScreenHandle>(null!);

  const openConversation = () => {
    setChatOpen(true);
    setShow(true);

    conversation.startChatbotConversation();
  };

  useEffect(() => {
    // Disable incoming notif if chat open
    disableIncomingNotification(chatOpen);

    // discard any notif or overlay present
    if (chatOpen) {
      discardMessageNotificationAndOverlay();
    }

    return () => {
      // Re-enable back notif
      disableIncomingNotification(false);
    };
  }, [chatOpen]);

  const closeConversation = useCallback(async () => {
    if (chatOpen) {
      await chatScreenRef.current.closeInfos?.();
      setChatOpen(false);
      // this will pause any audio/video playing after the chat is closed.
      // it's placed in a timeout since rerendering is computationally expensive
      // and it will execute after the animation has started and possibly completed
      setTimeout(rerenderChat, 250);
    }
  }, [chatOpen]);

  const focusInputOnOpen = useCallback(() => {
    if (chatOpen) {
      chatScreenRef.current.chatFooter?.focus();
    } else {
      setShow(false);
    }
  }, [chatOpen]);

  const filePreviewRes = useFilePreview({ key: selectedChatbot.id });

  return (
    <>
      <ChatInfo
        conversation={conversation}
        contact={selectedChatbot}
        isChatbot
        overlayMessages={openConversation}
        onClick={closeConversation}
      />
      <SlideOnTop
        open={chatOpen}
        layer={1}
        onAnimationComplete={focusInputOnOpen}
      >
        {show && (
          <React.Fragment key={key}>
            <ChatScreen
              ref={chatScreenRef}
              key={selectedChatbot.id}
              conversation={conversation}
              nestedChat
              closeChat={closeConversation}
              openContactInfoOverride={closeConversation}
              autoFocusInput={false}
              filePreviewRes={filePreviewRes}
            />
          </React.Fragment>
        )}
      </SlideOnTop>
    </>
  );
}
